import styles from "../css/Form.module.css";
import { useState } from "react";
import { Snackbar, Alert } from "@mui/material";

export default function Form() {
  const [email, setEmail] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [open, setOpen] = useState(false);

  const handleSubmit = async (event) => {
    event.preventDefault();
  
    await fetch('https://visualsearchbackend.faithbytenexus.tech/submit', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        email: email,
        ph_number: phoneNumber,
      }),
    });
  
    
    // Open the success alert
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <img src="https://news.ubc.ca/wp-content/uploads/2023/08/AdobeStock_559145847.jpeg" alt="Background" />
      <div className={styles.container}>
        <form onSubmit={handleSubmit}>
          <h3>Reach Us</h3>
          <div className={styles["input-box"]}>
            <input 
              type="email" 
              placeholder="Email" 
              value={email} 
              onChange={(e) => setEmail(e.target.value)} 
              required
            />
            <i className="bx bx-envelope"></i>
          </div>

          <div className={styles["input-box"]}>
            <input 
              type="text" 
              placeholder="Phone Number" 
              value={phoneNumber} 
              onChange={(e) => setPhoneNumber(e.target.value)} 
              required
            />
            <i className="bx bx-lock-alt"></i>
          </div>
          <button className={styles.btn} type="submit">Submit</button>
          <div className={styles.register}>
            <p>
             <br /> We will call you soon
            </p>
          </div>
        </form>
      </div>
      
      {/* Success Alert */}
      <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
        <Alert onClose={handleClose} severity="success" sx={{ width: '100%' }}>
          Thanks, we will get in touch with you soon!
        </Alert>
      </Snackbar>
    </>
  );
}
