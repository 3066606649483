import React, { useState, useEffect, useCallback } from 'react';
import { Container, Grid, Box, Typography } from "@mui/material";
import InputImages from '../components/InputImages';
import ImagesGrid from '../components/ImagesGrid';
import SearchImage from '../components/SearchImage';
import { useNavigate } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';  // Import uuidv4 from 'uuid'
import styles from '../css/Header.module.css';
import "../App.css";

function Main() {
  const [images, setImages] = useState([]);
  const [numFiles, setNumFiles] = useState(0);
  const navigate = useNavigate();

  const checkNumberOfFiles = useCallback(async (uuid) => {
    try {
      const formData = new FormData();
      formData.append('uuid', uuid);

      const response = await fetch('https://visualsearchbackend.faithbytenexus.tech/number-of-files', {
        method: 'POST',
        body: formData,
      });

      if (response.ok) {
        const result = await response.json();
        updateNumFiles(result.number_of_files);
      } else {
        console.error("Failed to get the number of files");
      }
    } catch (error) {
      console.error("An error occurred:", error);
    }
  }, []); 

  useEffect(() => {
    const uuid = localStorage.getItem('uuid');
    if (uuid) {
      checkNumberOfFiles(uuid);
    }
  }, [checkNumberOfFiles]);

  const handleImagesChange = (newImages) => {
    setImages(newImages);
  };

  const updateNumFiles = (newNumFiles) => {
    setNumFiles(newNumFiles);
  };

  const handleClick = () => {
    navigate('/reach-us');
  };

  const removeUuid = async () => {
    localStorage.removeItem('uuid');

    const newUuid = uuidv4();
    localStorage.setItem('uuid', newUuid);

    await checkNumberOfFiles(newUuid);
  };

  return (
    <div
      className="App"
      style={{
        backgroundImage: `url('https://wallpaperaccess.com/full/6464796.png')`, // High-tech background
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        backgroundAttachment: 'fixed',
        minHeight: '100vh',
        padding: '20px',
      }}
    >
      <Container>
        <Grid
          container
          spacing={4}
          justifyContent="center"
          sx={{ maxWidth: '1200px' }}
        >
          <Grid item xs={12} textAlign="center">
            <Box sx={{ marginTop: 7 }}>
              <button className={styles.title}>
                Faith Byte Nexus - Visual Search
              </button>
            </Box>
          </Grid>

          <Grid
            item
            xs={12}
            sm={12}
            md={12}
            lg={12}
            display="flex"
            flexDirection="column"
            justifyContent="center"
            alignItems="center"
            textAlign="center"
            sx={{ marginTop: 3 }}
          >
            <Box sx={{ marginTop: 4 }}>
              <Typography variant={{ xs: 'h6', md: 'h2' }} className={styles.gblue}>
                Enrich the search experience with visually similar images and products from your business
              </Typography>
            </Box>
            <Box>
              <h6 className={styles.gblue}>- The world is visual — now search is too</h6>
            </Box>
            <Box>
              <InputImages onImagesChange={handleImagesChange} updateNumFiles={updateNumFiles} />
            </Box>
            <Box>
              <p className={styles.gblue}>Click here to upload images</p>
            </Box>
          </Grid>

          <Grid
            item
            xs={12}
            sm={12}
            md={12}
            lg={12}
            display="flex"
            justifyContent="center"
            textAlign="center"
            sx={{ marginTop: 3 }}
          >
            <SearchImage numFiles={numFiles} />
          </Grid>

          <Grid
            item
            xs={12}
            sm={12}
            md={12}
            lg={12}
            display="flex"
            justifyContent="center"
            textAlign="center"
            sx={{ marginTop: 3 }}
          >
            <ImagesGrid images={images} />
          </Grid>

          <Grid
            item
            xs={12}
            sm={12}
            md={12}
            lg={12}
            display="flex"
            justifyContent="center"
            textAlign="center"
          >
            <button type="button" className={styles.btn} onClick={removeUuid}>
              <strong>Try again</strong>
            </button>
          </Grid>

          <Grid
            item
            xs={12}
            sm={12}
            md={12}
            lg={12}
            display="flex"
            justifyContent="center"
            textAlign="center"
            sx={{ marginTop: 3 }}
          >
            <p className={styles.glow}>Want to integrate visual search?</p>
          </Grid>

          <Grid
            item
            xs={12}
            sm={12}
            md={12}
            lg={12}
            display="flex"
            justifyContent="center"
            textAlign="center"
          >
            <button type="button" className={styles.btn} onClick={handleClick}>
              <strong>Reach us now</strong>
              <div id={styles.containerStars}>
                <div id={styles.stars}></div>
              </div>

              <div id={styles.glow}>
                <div className={styles.circle}></div>
                <div className={styles.circle}></div>
              </div>
            </button>
          </Grid>

        </Grid>
      </Container>
    </div>
  );
}

export default Main;
