import React, { useState, useEffect } from 'react';
import styles from "../css/InputImages.module.css";
import Alert from '@mui/material/Alert';
import Snackbar from '@mui/material/Snackbar';
import { v4 as uuidv4 } from 'uuid';
import Loader from './Loader';

export default function InputImages({ onImagesChange, updateNumFiles }) {
    const [alertOpen, setAlertOpen] = useState(false);
    const [alertMessage, setAlertMessage] = useState('');
    const [uuid, setUuid] = useState('');
    const [loading, setLoading] = useState(false); // State to track the loader

    useEffect(() => {
        let storedUuid = localStorage.getItem('uuid');
        
        if (!storedUuid) {
            storedUuid = uuidv4();
            localStorage.setItem('uuid', storedUuid);
        }

        setUuid(storedUuid);
    }, []);

    const handleFileChange = async (event) => {
        const files = Array.from(event.target.files);
        const imageFiles = files.filter(file => file.type.startsWith('image/'));

        if (imageFiles.length > 20) {
            setAlertMessage('Maximum you can select only 20 images.');
            setAlertOpen(true);
            event.target.value = '';
            return;
        }

        const imageUrls = imageFiles.map(file => URL.createObjectURL(file));

        onImagesChange(imageUrls);

        const formData = new FormData();
        imageFiles.forEach((file, index) => {
            formData.append('files', file); 
        });
        formData.append('uuid', uuid);

        setLoading(true); // Start the loader when upload begins
        try {
            const response = await fetch('https://visualsearchbackend.faithbytenexus.tech/upload', {
                method: 'POST',
                body: formData,
            });

            const result = await response.json();

            if (response.ok) {
                setAlertMessage(result.message);
                setAlertOpen(true);
                // Update number of files
                await checkNumberOfFiles(uuid);
            } else {
                setAlertMessage(result.error || 'Failed to upload images.');
                setAlertOpen(true);
            }
        } catch (error) {
            setAlertMessage('Error occurred while uploading images.');
            setAlertOpen(true);
        } finally {
            setLoading(false); // Stop the loader after upload completes
        }
    };

    const checkNumberOfFiles = async (uuid) => {
        try {
            const formData = new FormData();
            formData.append('uuid', uuid);

            const response = await fetch('https://visualsearchbackend.faithbytenexus.tech/number-of-files', {
                method: 'POST',
                body: formData,
            });

            if (response.ok) {
                const result = await response.json();
                updateNumFiles(result.number_of_files);
            } else {
                console.error("Failed to get the number of files");
            }
        } catch (error) {
            console.error("An error occurred:", error);
        }
    };

    const handleCloseAlert = () => {
        setAlertOpen(false);
    };

    return (
        <div className={loading ? `${styles.inputdiv} ${styles.blurred}` : styles.inputdiv}>
            {loading && (
        <Loader/>
    )}

    {!loading && (
        <>
            <input 
                className={styles.input} 
                name="files" 
                type="file" 
                accept="image/*" 
                multiple 
                onChange={handleFileChange} 
            />
            <svg 
                xmlns="http://www.w3.org/2000/svg" 
                width="1em" 
                height="1em" 
                strokeLinecap="round" 
                stroke-linecap="round" 
                viewBox="0 0 24 24" 
                strokeWidth="2" 
                fill="none" 
                stroke="currentColor" 
                className={styles.icon}
            >
                <polyline points="16 16 12 12 8 16"></polyline>
                <line y2="21" x2="12" y1="12" x1="12"></line>
                <path d="M20.39 18.39A5 5 0 0 0 18 9h-1.26A8 8 0 1 0 3 16.3"></path>
                <polyline points="16 16 12 12 8 16"></polyline>
            </svg>
        </>
    )}
            <Snackbar
                open={alertOpen}
                autoHideDuration={6000}
                onClose={handleCloseAlert}
                anchorOrigin={{ vertical: 'center', horizontal: 'center' }}
            >
                <Alert onClose={handleCloseAlert} severity="warning" sx={{ width: '100%' }}>
                    {alertMessage}
                </Alert>
            </Snackbar>
        </div>
    );
}
