import { useRef, useState, useEffect } from "react";
import { Modal, Box } from "@mui/material";
import styles from "../css/SearchImage.module.css";
import Loader from "./Loader";

export default function SearchImage({ numFiles }) {
    const [uuid, setUuid] = useState('');
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [imageSrc, setImageSrc] = useState('');
    const [loading, setLoading] = useState(false); // Loading state to control Loader visibility
    const fileInputRef = useRef(null);

    useEffect(() => {
        let storedUuid = localStorage.getItem('uuid');
        setUuid(storedUuid);
    }, []);

    const handleButtonClick = () => {
        fileInputRef.current.click();
    };

    const handleFileChange = async (e) => {
        const file = e.target.files[0];
        if (file && uuid) {
            setLoading(true); // Start showing loader
            try {
                const formData = new FormData();
                formData.append('file', file);
                formData.append('uuid', uuid);

                const response = await fetch('https://visualsearchbackend.faithbytenexus.tech/image-search', {
                    method: 'POST',
                    body: formData,
                });

                if (response.ok) {
                    const result = await response.json();
                    const imageUrl = "https://visualsearchbackend.faithbytenexus.tech/" + result.file_url;

                    setImageSrc(imageUrl);
                    setIsModalOpen(true);
                } else {
                    const error = await response.json();
                    console.error("Error:", error);
                }
            } catch (error) {
                console.error("An error occurred:", error);
            } finally {
                setLoading(false); 
            }
        } else {
            console.error("No file selected or UUID is missing");
        }
    };

    const closeModal = () => {
        setIsModalOpen(false);
    };

    return (
        <>
            {loading ? (
                <Loader />
            ) : (
                numFiles > 0 ? (
                    <>
                        <button className={styles.button} onClick={handleButtonClick}>
                            <svg xmlns="http://www.w3.org/2000/svg" width="29" height="29" viewBox="0 0 29 29" fill="none">
                            </svg>
                            Search image <br /><br /> Number of files: {numFiles}
                        </button>
                    </>
                ) : (
                    <p className={styles.text}>Number of files: {numFiles}</p>
                )
            )}
            
            <input
                type="file"
                accept="image/*"
                onChange={handleFileChange}
                ref={fileInputRef}
                style={{ display: "none" }}
                multiple={false}
            />

            <Modal
                open={isModalOpen}
                onClose={closeModal}
                aria-labelledby="modal-title"
                aria-describedby="modal-description"
            >
                <Box
                    sx={{
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                        width: 250,
                        height: 250,
                        bgcolor: 'background.paper',
                        border: '2px solid #000',
                        boxShadow: 24,
                        p: 4,
                    }}
                >
                    <img
                        src={imageSrc}
                        alt={imageSrc}
                    />
                </Box>
            </Modal>
        </>
    );
}
